<template>
  <div>
    <v-dialog v-if="selected" scrollable v-model="showEdit" max-width="80%">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card>
          <v-card-title class="title_wrapper">
            <span class="text-h5">{{ `${$t("edititem")} POS` }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">POS Data</h6>
                </v-col>
              </v-row>
              <v-divider class="mx-0 mt-0 mb-4"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.name"
                    v-bind:label="$t('brands.name')"
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.storeM2"
                    type="number"
                    v-bind:label="$t('Store m2')"
                    :rules="storem2Rules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.po.chainId"
                    v-bind:label="$t('chain')"
                    :items="chain_list"
                    item-text="name"
                    item-value="id"
                    filled
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-autocomplete
                    v-model="selected.po.channelId"
                    v-bind:label="$t('channel')"
                    :items="channel_list"
                    item-text="name"
                    item-value="id"
                    filled
                    required
                    :rules="requiredRules"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.town"
                    v-bind:label="$t('town')"
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.postalCode"
                    label="CP"
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.address"
                    v-bind:label="$t('address')"
                    required
                    :rules="requiredRules"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.addressObservation"
                    v-bind:label="$t('address observation')"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <contactPersonTable
                    :posData="selected.po"
                    :updatePosContacts="updatePosContacts"
                    :apiGetBrandsUrl="
                      `worksession/getbasedata?isFlag=searchbrand`
                    "
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">Fiscal Data</h6>
                </v-col>
              </v-row>
              <v-divider class="mx-0 mt-0 mb-4"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.fiscalName"
                    v-bind:label="`Fiscal ${$t('brands.name')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.vatNumber"
                    v-bind:label="`${$t('brands.vat')} ${$t('number')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.fiscalTown"
                    v-bind:label="`Fiscal ${$t('town')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.fiscalPostalCode"
                    v-bind:label="`Fiscal CP`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="6">
                  <v-text-field
                    v-model="selected.po.fiscalAddress"
                    v-bind:label="`Fiscal ${$t('address')}`"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">Other Data</h6>
                </v-col>
              </v-row>
              <v-divider class="mx-0 mt-0 mb-4"></v-divider>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <template v-for="i in selected.brandSurveys.length">
                    <v-row :key="i">
                      <v-col cols="12" sm="5" md="5">
                        <v-autocomplete
                          v-model="selected.brandSurveys[i - 1].id"
                          v-bind:label="$t('brands.brand')"
                          v-bind:placeholder="$t('start_typing_to_search')"
                          item-text="name"
                          item-value="id"
                          :items="brandList"
                          filled
                          required
                          :rules="requiredRules"
                          hide-details="auto"
                          @change="
                            onChangeBrand(
                              selected.brandSurveys[i - 1].id,
                              i - 1
                            )
                          "
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="5" md="5">
                        <v-autocomplete
                          v-model="selected.brandSurveys[i - 1].surveys"
                          v-bind:label="$t('Form')"
                          v-bind:placeholder="$t('start_typing_to_search')"
                          item-text="name"
                          item-value="id"
                          :items="selectedSurveyList[i - 1]"
                          multiple
                          filled
                          required
                          :rules="requiredRulesArray"
                          hide-details="auto"
                        ></v-autocomplete>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="2"
                        md="2"
                        class="d-flex align-center justify-content-center"
                      >
                        <b-button
                          @click="onClickRemoveBrandSurveys(i - 1)"
                          style="color: white;"
                          variant="danger"
                          size="sm"
                          v-if="selected.brandSurveys.length > 1"
                        >
                          <b-icon icon="trash"></b-icon>
                        </b-button>
                      </v-col>
                    </v-row>
                  </template>
                  <b-button
                    @click="onClickAddBrandSurveys"
                    style="color: white;"
                    variant="primary"
                    size="sm"
                    class="mt-4"
                  >
                    <b-icon icon="plus-circle"></b-icon>
                    Add
                  </b-button>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-bind:label="$t('Set New Visit Day')"
                    v-model="selected.currentDays"
                    :items="weekdays"
                    item-text="label"
                    item-value="value"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('currentDays')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selected.currentDays.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconVisitdays }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-bind:label="$t('Set Week')"
                    v-model="selected.currentWeeks"
                    :items="weeks"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('currentWeeks')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selected.currentWeeks.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconSettedWeeks }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-autocomplete
                    v-bind:label="$t('periods')"
                    v-model="selected.currentPeriods"
                    :items="periodList"
                    filled
                    multiple
                    required
                    :rules="requiredRulesArray"
                    hide-details="auto"
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="toggleSelectAllOptions('currentPeriods')"
                      >
                        <v-list-item-action>
                          <v-icon
                            :color="
                              selected.currentPeriods.length > 0
                                ? 'indigo darken-4'
                                : ''
                            "
                            >{{ iconPeriods }}</v-icon
                          >
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>Select All</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-textarea
                    v-bind:label="$t('comments')"
                    v-model="selected.gpvComments"
                    hide-details="auto"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <template v-for="i in attachmentLimitCount">
                  <v-col cols="12" sm="12" md="6" :key="i" class="text-center">
                    <h6 class="my-2">{{ $t("Picture") }} {{ i }}</h6>
                    <picture-input
                      :crop="false"
                      class="my-picture-input"
                      :ref="`pictureInput`"
                      width="500"
                      height="300"
                      size="5"
                      :prefill="
                        getImageUri(
                          selected.attachments[i - 1] &&
                            selected.attachments[i - 1].static
                            ? selected.attachments[i - 1].static
                            : null
                        )
                      "
                      accept="image/jpeg,image/png"
                      button-class="btn btn-outline-info"
                      aspect="Landscape"
                      @change="onPicture(i - 1)"
                    >
                    </picture-input>
                    <small style="text-align: center; font-size: 11px; "
                      >5MB max.</small
                    >
                    <div></div>
                    <v-btn
                      v-if="
                        selected.attachments[i - 1] &&
                          selected.attachments[i - 1].static
                      "
                      color="blue darken-1"
                      @click="
                        downloadSelectedImage(
                          selected.attachments[i - 1].static
                        )
                      "
                    >
                      <b-icon icon="download" aria-hidden="true"></b-icon>
                      {{ $t("Download") }}
                    </v-btn>
                    <v-btn
                      v-if="
                        (selected.attachments[i - 1] &&
                          selected.attachments[i - 1].static) ||
                          (selected.attachments[i - 1] &&
                            selected.attachments[i - 1].img)
                      "
                      class="ml-2"
                      color="red darken-1"
                      @click="removeSelectedImage(i - 1)"
                    >
                      <b-icon icon="trash"></b-icon>
                      {{ $t("delete") }}
                    </v-btn>
                  </v-col>
                </template>
              </v-row>

              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ $t("Responsible OK / NOT OK") }}
                    <span v-if="isResponsableEditable" class="required ml-2"
                      >*</span
                    >
                    :
                  </h6>
                  <v-autocomplete
                    v-model="selected.responsableApprovalStatus"
                    :items="responsableApprovalStatusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="isResponsableEditable ? requiredRules : []"
                    :readonly="!isResponsableEditable"
                    v-bind:class="
                      !isResponsableEditable ? 'readonly_component' : ''
                    "
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{
                      `${this.$t("salesforce.Parent")} ${this.$t("comments")}`
                    }}
                    <span v-if="isResponsableEditable" class="required ml-2"
                      >*</span
                    >
                    :
                  </h6>
                  <v-textarea
                    v-model="selected.responsableComments"
                    :readonly="!isResponsableEditable"
                    v-bind:class="
                      `gpv_comment_text ${
                        !isResponsableEditable ? 'readonly' : ''
                      }`
                    "
                    required
                    :rules="isResponsableEditable ? requiredRules : []"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row v-if="isShowAdminInputs">
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("Final")} ${this.$t("Decision")}` }}
                    <span v-if="isAdminEditable" class="required ml-2">*</span>
                    :
                  </h6>
                  <v-autocomplete
                    v-model="selected.adminApprovalStatus"
                    :items="adminApprovalStatusValues"
                    item-text="label"
                    item-value="value"
                    filled
                    required
                    :rules="isAdminEditable ? requiredRules : []"
                    :readonly="!isAdminEditable"
                    v-bind:class="!isAdminEditable ? 'readonly_component' : ''"
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="isShowAdminInputs">
                <v-col cols="12" md="12">
                  <h6 class="my-2">
                    {{ `${this.$t("Final")} ${this.$t("comments")}` }}
                    <span v-if="isAdminEditable" class="required ml-2">*</span>
                    :
                  </h6>
                  <v-textarea
                    v-model="selected.adminComments"
                    :readonly="!isAdminEditable"
                    v-bind:class="
                      `gpv_comment_text ${!isAdminEditable ? 'readonly' : ''}`
                    "
                    required
                    :rules="isAdminEditable ? requiredRules : []"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeEditDialog">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              :loading="isSaving"
              @click="onSaveClick(selected)"
              ref="btnSave"
              v-if="isShowSaveBtn"
            >
              {{ $t("save") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-card elevation="2" outlined shaped tile class="mb-2">
      <v-card-title>{{ `${$t("newitem")} POS` }}</v-card-title>
      <v-card-text>
        <div class="status_wrapper d-flex justify-content-center align-center">
          <b-card
            bg-variant="dark"
            v-bind:header="$t('Pending Responsible')"
            class="text-center mr-2"
            style="width:200px"
          >
            <b-card-text>{{ pendingCountResponsable }}</b-card-text>
          </b-card>
          <b-card
            bg-variant="dark"
            v-bind:header="$t('Pending Admin')"
            class="text-center ml-2"
            style="width:200px"
          >
            <b-card-text>{{ pendingCountAdmin }}</b-card-text>
          </b-card>
        </div>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="text-right pe-5">
            <v-btn
              class="text-none text-subtitle-1"
              color="primary"
              size="small"
              @click="approveSelectedPos"
              >Aprobar</v-btn
            >
            <v-btn color="warning" dark class="ml-2" @click="downloadExcel">
              <b-icon icon="file-excel-fill" aria-hidden="true"></b-icon>
              Export
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          class="status_wrapper d-flex justify-content-center align-center"
        >
          <v-col cols="12" sm="12" md="12"> </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <ve-table
              ref="tableRefroutes"
              id="datatable"
              fixed-header
              max-height="calc(100vh - 330px)"
              :columns="columns"
              :table-data="data"
              :border-around="true"
              :border-x="true"
              :border-y="true"
              :scroll-width="1500"
              :sort-option="sortOption"
              :cell-style-option="cellStyleOption"
              row-key-field-name="id"
              :checkbox-option="checkboxOption"
            />
            <div v-show="showEmpty" class="empty-data">Data Empty.</div>
            <div class="table-pagination mt-2 text-right">
              <ve-pagination
                :total="totaldata"
                :page-index="pageIndex"
                :page-size="pageSize"
                :page-size-option="pageOptions"
                @on-page-number-change="pageNumberChange"
                @on-page-size-change="pageSizeChange"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";
import download from "js-file-download";
import _ from "lodash";
import PictureInput from "vue-picture-input";
import contactPersonTable from "@/components/contactPersonTable";

export default {
  name: "newposEditModal",
  components: {
    PictureInput,
    contactPersonTable
  },
  data: function() {
    return {
      filterlist_fetch_url: "pos/newrequest/getfilterlist",
      filterable_col_list: [
        "routeId",
        "userId",
        "name",
        "chainname",
        "town",
        "postalCode",
        "address",
        "addressObservation",
        "contact",
        "email",
        "phone",
        "phone2",
        "fiscalName",
        "vatNumber",
        "fiscalTown",
        "fiscalPostalCode",
        "fiscalAddress",
        "brands",
        "gpvComments",
        "currentPeriods",
        "currentWeeks",
        "currentDays",
        "responsableComments",
        "adminComments",
        "responsableApprovalStatus",
        "adminApprovalStatus",
        "createdAt"
      ],
      filterCriteria: {
        routeId: {},
        userId: {},
        name: {},
        chainname: {},
        town: {},
        postalCode: {},
        address: {},
        addressObservation: {},
        contact: {},
        email: {},
        phone: {},
        phone2: {},
        fiscalName: {},
        vatNumber: {},
        fiscalTown: {},
        fiscalPostalCode: {},
        fiscalAddress: {},
        brands: {},
        gpvComments: {},
        currentPeriods: {},
        currentWeeks: {},
        currentDays: {},
        responsableComments: {},
        adminComments: {},
        responsableApprovalStatus: {},
        adminApprovalStatus: {},
        createdAt: {}
      },
      checkboxOption: {
        // row select change event
        selectedRowChange: ({ row, isSelected, selectedRowKeys }) => {
          this.selectedRowKeys = selectedRowKeys;
        },
        // selected all change event
        selectedAllChange: ({ isSelected, selectedRowKeys }) => {
          this.selectedRowKeys = selectedRowKeys;
        }
      },
      cellStyleOption: {
        headerCellClass: ({ column, rowIndex }) => {
          if (this.filterable_col_list.includes(column.field)) {
            return "filterable-column " + column.field;
          }
        }
      },
      loadingInstance: null,
      sorting: null,
      sortOption: {
        sortChange: params => {
          this.sortChange(params);
        }
      },
      pageIndex: 1,
      pageSize: 100,
      pageOptions: [10, 50, 100],
      showEmpty: false,

      totaldata: 0,
      data: [],
      responsableApprovalStatusValues: [],
      adminApprovalStatusValues: [],
      weekdays: [],
      periodsCount: 13,
      weekCount: 4,
      weeks: [],
      periodList: [],
      pendingCountResponsable: 0,
      pendingCountAdmin: 0,
      selectedRowKeys: [],

      /********** image dialog options **********/
      showImageDialog: false,
      selected_image: null,

      /********** create or edit dialog options **********/
      showEdit: false,
      selected: null,
      editedIndex: -1,
      valid: true,
      requiredRules: [v => !!v || "Required"],
      requiredEmailRules: [
        v => !!v || "Required",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail must be valid"
      ],
      requiredRulesArray: [v => (!!v && v.length > 0) || "Required"],
      storem2Rules: [
        v =>
          !v ||
          (v >= 0 && v <= 2000) ||
          "Incorrect value ! this value should be between 0 and 2000."
      ],
      isSaving: false,
      chain_list: [],
      channel_list: [],
      brandList: [],
      surveyList: [],
      selectedSurveyList: [],
      attachmentLimitCount: 4
    };
  },
  watch: {},
  computed: {
    ...mapGetters("auth", [
      "isAuthenticated",
      "isSPV",
      "isManager",
      "isSubAdmin",
      "isAdmin",
      "user"
    ]),
    isResonsableRole() {
      return this.isSPV || this.isManager || this.isSubAdmin;
    },
    isAdminRole() {
      return this.isAdmin;
    },
    isResponsableEditable() {
      if (
        this.selected &&
        this.isResonsableRole &&
        this.selected.adminApprovalStatus !== "APPROVED"
      ) {
        return true;
      }
      return false;
    },
    isAdminEditable() {
      if (this.isAdminRole) {
        return true;
      }
      return false;
    },
    isShowAdminInputs() {
      if (this.selected) {
        if (this.isAdminRole) {
          return true;
        } else if (
          this.isResonsableRole &&
          this.selected.adminApprovalStatus !== "PENDING"
        ) {
          return true;
        }
      }
      return false;
    },
    isShowSaveBtn() {
      if (
        this.isResonsableRole &&
        this.selected.adminApprovalStatus === "APPROVED"
      ) {
        return false;
      }
      return true;
    },
    col_checkbox() {
      return {
        field: "",
        key: "a",
        type: "checkbox",
        title: "",
        width: 50,
        align: "center",
        fixed: "left"
      };
    },
    col_id() {
      return {
        key: "id",
        title: "ID",
        field: "id",
        align: "left",
        width: 100,
        sortBy: "desc"
      };
    },
    col_date() {
      return {
        key: "createdAt",
        title: "Fecha",
        field: "createdAt",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.createdAt.slice(0, 10)}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["createdAt"]["from"]}
                  value={this.filterCriteria["createdAt"]["from"]}
                  placeholder="From - "
                />
                <br />
                <input
                  type="date"
                  class="filter_date_picker"
                  v-model={this.filterCriteria["createdAt"]["to"]}
                  value={this.filterCriteria["createdAt"]["to"]}
                  placeholder="To - "
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "createdAt")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_user() {
      return {
        key: "userId",
        title: "GPV",
        field: "userId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <span>{row.user && row.user.name + " " + row.user.surname}</span>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["userId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`userId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["userId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "userId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_route() {
      return {
        key: "routeId",
        title: this.$t("route"),
        field: "routeId",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.route && row.route.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["routeId"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`routeId`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["routeId"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "routeId")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_posId() {
      return {
        key: "posId",
        title: "Id POS",
        field: "posId",
        align: "left",
        width: 100,
        sortBy: ""
      };
    },
    col_pos_detail() {
      return {
        key: "pos_detail",
        title: `POS ${this.$t("detail")}`,
        field: "pos_detail",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.pos_detail}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["pos_detail"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`pos_detail`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["pos_detail"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "pos_detail")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_name() {
      return {
        key: "name",
        title: `POS ${this.$t("brands.name")}`,
        field: "name",
        align: "left",
        width: 150,
        sortBy: "",
        fixed: "left",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["name"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`name`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["name"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "name")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_chain() {
      return {
        key: "chainname",
        title: `${this.$t("chain")}`,
        field: "chainname",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.chain && row.po.chain.name}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["chainname"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`chainname`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["chainname"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "chainname")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_town() {
      return {
        key: "town",
        title: `${this.$t("town")}`,
        field: "town",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.town}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["town"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`town`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["town"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "town")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_postalCode() {
      return {
        key: "postalCode",
        title: `CP`,
        field: "postalCode",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.postalCode}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["postalCode"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`postalCode`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["postalCode"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "postalCode")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_address() {
      return {
        key: "address",
        title: `${this.$t("address")}`,
        field: "address",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.address}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["address"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`address`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["address"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "address")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_addressObservation() {
      return {
        key: "addressObservation",
        title: `${this.$t("address observation")}`,
        field: "addressObservation",
        align: "left",
        width: 200,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.addressObservation}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["addressObservation"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`addressObservation`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["addressObservation"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "addressObservation")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_fiscalName() {
      return {
        key: "fiscalName",
        title: `Fiscal ${this.$t("brands.name")}`,
        field: "fiscalName",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.fiscalName}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["fiscalName"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`fiscalName`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["fiscalName"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "fiscalName")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_vatNumber() {
      return {
        key: "vatNumber",
        title: `${this.$t("brands.vat")} ${this.$t("number")}`,
        field: "vatNumber",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.vatNumber}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["vatNumber"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`vatNumber`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["vatNumber"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "vatNumber")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_fiscalTown() {
      return {
        key: "fiscalTown",
        title: `Fiscal ${this.$t("town")}`,
        field: "fiscalTown",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.fiscalTown}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["fiscalTown"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`fiscalTown`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["fiscalTown"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "fiscalTown")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_fiscalPostalCode() {
      return {
        key: "fiscalPostalCode",
        title: `Fiscal CP`,
        field: "fiscalPostalCode",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.fiscalPostalCode}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["fiscalPostalCode"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`fiscalPostalCode`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["fiscalPostalCode"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "fiscalPostalCode")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_fiscalAddress() {
      return {
        key: "fiscalAddress",
        title: `Fiscal ${this.$t("address")}`,
        field: "fiscalAddress",
        align: "left",
        width: 150,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.po && row.po.fiscalAddress}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["fiscalAddress"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`fiscalAddress`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["fiscalAddress"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "fiscalAddress")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_brands() {
      return {
        key: "brands",
        title: `${this.$t("brands.brands")} - (${this.$t("Form")})`,
        field: "brands",
        align: "left",
        width: 250,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return (
            <div>
              {row.brands.map(el => {
                return (
                  <p class="mb-0">
                    <span style="font-weight: 800;">{el.name}</span> - (
                    {el.surveysLabel})
                  </p>
                );
              })}
            </div>
          );
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["brands"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`brands`}
                  isFullText={true}
                  filterCriteria={this.filterCriteria["brands"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "brands")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_gpvComments() {
      return {
        key: "gpvComments",
        title: `GPV ${this.$t("Reason")}`,
        field: "gpvComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["gpvComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`gpvComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["gpvComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "gpvComments")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_current_periods() {
      return {
        key: "currentPeriods",
        title: `${this.$t("periods")}`,
        field: "currentPeriods",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentPeriodsLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let periods = [];
            for (let i = 1; i <= this.periodsCount; i++) {
              periods.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["currentPeriods"]}
                  dataList={periods}
                  filterCriteria={this.filterCriteria["currentPeriods"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "currentPeriods")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_current_weeks() {
      return {
        key: "currentWeeks",
        title: `${this.$t("Week")}s`,
        field: "currentWeeks",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentWeeksLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            let weeks = [];
            for (let i = 1; i <= this.weekCount; i++) {
              weeks.push(i.toString());
            }
            return (
              <div class="custom-filter">
                <filterableCheckboxes
                  v-model={this.filterCriteria["currentWeeks"]}
                  dataList={weeks}
                  filterCriteria={this.filterCriteria["currentWeeks"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "currentWeeks")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_current_days() {
      return {
        key: "currentDays",
        title: `${this.$t("Day")}s`,
        field: "currentDays",
        align: "left",
        width: 180,
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.currentDaysLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["currentDays"]}
                  dataList={this.weekdays}
                  filterCriteria={this.filterCriteria["currentDays"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "currentDays")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_responsableApprovalStatus() {
      return {
        key: "responsableApprovalStatus",
        title: `${this.$t("salesforce.Parent")} OK`,
        field: "responsableApprovalStatus",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.responsableApprovalStatusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["responsableApprovalStatus"]}
                  dataList={this.responsableApprovalStatusValues}
                  filterCriteria={
                    this.filterCriteria["responsableApprovalStatus"]
                  }
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsableApprovalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_responsableComments() {
      return {
        key: "responsableComments",
        title: `${this.$t("salesforce.Parent")} ${this.$t("comments")}`,
        field: "responsableComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["responsableComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`responsableComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["responsableComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "responsableComments")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_adminApprovalStatus() {
      return {
        key: "adminApprovalStatus",
        title: `${this.$t("Final")} ${this.$t("Decision")}`,
        field: "adminApprovalStatus",
        align: "left",
        width: 180,
        sortBy: "",
        renderBodyCell: ({ row, column, rowIndex }, h) => {
          return <span>{row.adminApprovalStatusLabel}</span>;
        },
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableCheckboxesOthers
                  v-model={this.filterCriteria["adminApprovalStatus"]}
                  dataList={this.adminApprovalStatusValues}
                  filterCriteria={this.filterCriteria["adminApprovalStatus"]}
                  itemvalue="value"
                  itemlabel="label"
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() =>
                      this.searchCancel(closeFn, "adminApprovalStatus")
                    }
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    col_adminComments() {
      return {
        key: "adminComments",
        title: `${this.$t("Final")} ${this.$t("comments")}`,
        field: "adminComments",
        align: "left",
        width: 250,
        sortBy: "",
        filterCustom: {
          defaultVisible: false,
          render: ({ showFn, closeFn }, h) => {
            return (
              <div class="custom-filter">
                <filterableAjaxCheckboxes
                  v-model={this.filterCriteria["adminComments"]}
                  apiUrl={`${this.filterlist_fetch_url}`}
                  columnName={`adminComments`}
                  isFullText={false}
                  filterCriteria={this.filterCriteria["adminComments"]}
                />
                <div class="custom-filter-operation">
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchCancel(closeFn, "adminComments")}
                  >
                    {this.$t("cancel")}
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    on-click={() => this.searchConfirm(closeFn)}
                  >
                    {this.$t("Search")}
                  </v-btn>
                </div>
              </div>
            );
          }
        }
      };
    },
    columns() {
      return [
        this.col_checkbox,
        this.col_id,
        this.col_date,
        this.col_user,
        this.col_route,
        this.col_posId,
        this.col_name,
        this.col_chain,
        this.col_town,
        this.col_postalCode,
        this.col_address,
        this.col_addressObservation,
        this.col_fiscalName,
        this.col_vatNumber,
        this.col_fiscalTown,
        this.col_fiscalPostalCode,
        this.col_fiscalAddress,
        this.col_brands,
        this.col_current_periods,
        this.col_current_weeks,
        this.col_current_days,
        this.col_gpvComments,
        this.col_responsableApprovalStatus,
        this.col_responsableComments,
        this.col_adminApprovalStatus,
        this.col_adminComments,
        {
          key: "actions",
          title: this.$t("edititem"),
          field: "actions",
          align: "center",
          width: 100,
          fixed: "right",
          renderBodyCell: ({ row, column, rowIndex }, h) => {
            return (
              <div>
                <v-btn
                  color="blue darken-1"
                  text
                  on-click={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    setTimeout(() => {
                      this.onItemEdit(row);
                    }, 100);
                  }}
                >
                  <b-icon icon="box-arrow-up-right" aria-hidden="true"></b-icon>
                </v-btn>
              </div>
            );
          }
        }
      ];
    },
    likesAllVisitdays() {
      return this.selected.currentDays.length === this.weekdays.length;
    },
    iconVisitdays() {
      if (this.likesAllVisitdays) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllSettedWeeks() {
      return this.selected.currentWeeks.length === this.weeks.length;
    },
    iconSettedWeeks() {
      if (this.likesAllSettedWeeks) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    },
    likesAllPeriods() {
      return this.selected.currentPeriods.length === this.periodList.length;
    },
    iconPeriods() {
      if (this.likesAllPeriods) return "mdi-close-box";
      return "mdi-checkbox-blank-outline";
    }
  },
  methods: {
    async approveSelectedPos() {
      if (
        window.confirm("¿Quieres aprobar todas las posiciones seleccionadas?")
      ) {
        this.isSaving = true;
        let posNewRequestId = [...this.selectedRowKeys];
        let posId = [];
        for (let posItem of this.data) {
          if (posNewRequestId.includes(posItem.id)) posId.push(posItem.posId);
        }
        try {
          await ApiService.put(`pos/approvenewrequest/selectedPos`, {
            posNewRequestId,
            posId,
            isAdmin: this.isAdmin
          });
          logInfo("Éxito !");
          this.$store.dispatch("auth/getGlobalState");
          this.getDataFromApi();
          this.isSaving = false;
        } catch (error) {
          this.isSaving = false;
        }
      }
    },
    async updatePosContacts(newPosContacts) {
      this.selected.po.pos_contacts = newPosContacts;
      await this.getDataFromApi();
    },
    getImageUri(imgStatic) {
      if (imgStatic && imgStatic?.type === "file") {
        return ApiService.getFileUri(imgStatic);
      }
      return ApiService.getImageUri(imgStatic);
    },
    pageNumberChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getDataFromApi();
    },
    pageSizeChange(pageSize) {
      this.pageIndex = 1;
      this.pageSize = pageSize;
      this.getDataFromApi();
    },
    sortChange(params) {
      this.sorting = params;
      this.getDataFromApi();
    },
    searchConfirm(closeFn) {
      closeFn();
      this.getDataFromApi();
    },
    searchCancel(closeFn, cancelFilterKey) {
      closeFn();
      if (cancelFilterKey === "idposbrand") {
        this.filterCriteria[cancelFilterKey] = null;
      } else {
        this.filterCriteria[cancelFilterKey] = {};
      }
      this.getDataFromApi();
    },
    show() {
      this.loadingInstance = this.$veLoading({
        target: document.querySelector("#datatable"),
        name: "flow" // gird, flow, wave, bounce,
      });
      this.loadingInstance.show();
    },
    close() {
      this.loadingInstance.close();
    },
    getQueryParams() {
      let query_params_string = "";
      let query_params = [];
      query_params.push("page=" + this.pageIndex);
      query_params.push("itemsPerPage=" + this.pageSize);
      if (this.sorting !== null) {
        for (const [key, value] of Object.entries(this.sorting)) {
          if (value !== null && value !== "") {
            query_params.push("sortby=" + key);
            query_params.push(
              "sortdesc=" + (value === "desc" ? "true" : "false")
            );
          }
        }
      }

      let filterModel = [];
      for (const [key, value] of Object.entries(this.filterCriteria)) {
        if (value !== "" && value !== null) {
          if (Object.keys(value).length > 0) {
            filterModel.push({
              columnField: key,
              filterValue: value
            });
          }
        }
      }
      console.log(filterModel);
      let filter_columns = document.querySelectorAll(
        "#datatable .filterable-column"
      );
      for (let i = 0; i < filter_columns.length; i++) {
        filter_columns[i].classList.remove("filter_active");
      }
      if (filterModel.length > 0) {
        filterModel.map(filter_item => {
          for (let i = 0; i < filter_columns.length; i++) {
            if (filter_columns[i].classList.contains(filter_item.columnField)) {
              filter_columns[i].classList.add("filter_active");
            }
          }
          return filter_item;
        });
      }

      query_params_string += query_params.join("&");
      if (filterModel.length > 0) {
        query_params_string +=
          "&filterModel=" + encodeURIComponent(JSON.stringify(filterModel));
      }
      return query_params_string;
    },
    async getDataFromApi() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/newrequest";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      try {
        const resp = await ApiService.get(fetch_url);
        this.data = resp.data;
        if (this.data.length > 0) {
          this.showEmpty = false;
        } else {
          this.showEmpty = true;
        }
        this.totaldata = resp.total;
        this.responsableApprovalStatusValues =
          resp.responsableApprovalStatusValues;
        this.adminApprovalStatusValues = resp.adminApprovalStatusValues;

        this.weekdays = resp.weekdays;
        this.periodsCount = resp.periodsCount;
        this.weekCount = resp.weekCount;
        this.periodList = [];
        for (let i = 1; i <= this.periodsCount; i++) this.periodList.push(i);
        this.weeks = [];
        for (let i = 1; i <= this.weekCount; i++) this.weeks.push(i);
        this.chain_list = resp.chain_list;
        this.channel_list = resp.channel_list;
        this.brandList = resp.brandList;
        this.surveyList = resp.surveyList;
        this.pendingCountResponsable = resp.pendingCountResponsable;
        this.pendingCountAdmin = resp.pendingCountAdmin;
        this.$store.dispatch("auth/getGlobalState");
        this.close();
      } catch (error) {
        this.close();
      }
    },
    async downloadExcel() {
      this.show();
      let query_params_string = this.getQueryParams();
      let fetch_url = "pos/newrequest/downloadexcel";
      if (query_params_string !== "") {
        fetch_url += "?" + query_params_string;
      }
      const response = await ApiService.get(fetch_url, {
        responseType: "blob"
      });
      download(response, "newrequest.xlsx");
      this.close();
    },
    onItemEdit(item) {
      this.selected = this.createSelectedModel(item);
      this.editedIndex = this.selected.id;
      this.showEdit = true;
    },
    closeEditDialog() {
      this.showEdit = false;
      this.$nextTick(() => {
        this.selected = null;
        this.editedIndex = -1;
      });
    },
    createSelectedModel(item) {
      console.log("createSelectedModelitem - ", item);
      let model = JSON.parse(
        JSON.stringify(_.omit(item, ["createdAt", "updatedAt"]))
      );
      model.brandSurveys = JSON.parse(JSON.stringify(item.brands));
      if (model.brandSurveys && Array.isArray(model.brandSurveys)) {
        model.brandSurveys = model.brandSurveys.map((el, index) => {
          let surveys = [];
          if (el?.surveys) {
            surveys = el.surveys.map(surel => surel.id);
            this.selectedSurveyList[index] = this.surveyList.filter(
              surlistItem => surlistItem.brandId === el.id
            );
          }
          return { ...el, surveys };
        });
      }
      model.attachments = [];
      for (let i = 0; i < this.attachmentLimitCount; i++) {
        let push_item = {
          img: null,
          static: null,
          orderIndex: 100
        };
        if (model.po && model.po.posAttachments && model.po.posAttachments[i]) {
          push_item = {
            img: null,
            static: model.po.posAttachments[i],
            orderIndex: model.po.posAttachments[i].pos_attachment.orderIndex
          };
        }
        model.attachments.push(push_item);
      }
      console.log("model.attachments - ", model.attachments);
      model.attachments.sort(function(a, b) {
        var x = a["orderIndex"];
        var y = b["orderIndex"];
        return x < y ? -1 : x > y ? 1 : 0;
      });
      console.log("model.attachments - ", model.attachments);
      console.log("createSelectedModelmodel - ", model);
      return model;
    },
    onClickRemoveBrandSurveys(removeIndex) {
      if (this.selected.brandSurveys.length > 1) {
        this.selected.brandSurveys = [
          ...this.selected.brandSurveys.slice(0, removeIndex),
          ...this.selected.brandSurveys.slice(removeIndex + 1)
        ];
        this.selectedSurveyList = [
          ...this.selectedSurveyList.slice(0, removeIndex),
          ...this.selectedSurveyList.slice(removeIndex + 1)
        ];
      }
    },
    onClickAddBrandSurveys() {
      if (this.selected) {
        let push_item = { id: null, surveys: null };
        this.selected.brandSurveys.push(push_item);
      }
    },
    onPicture(changedIndex) {
      if (
        this.selected.attachments[changedIndex] &&
        this.$refs["pictureInput"][changedIndex].file
      ) {
        this.selected.attachments[changedIndex].img = this.$refs[
          "pictureInput"
        ][changedIndex].file;
      }
    },
    async downloadSelectedImage(downloadFile) {
      let selected_image = this.getImageUri(downloadFile ? downloadFile : null);
      try {
        const response = await ApiService.get(selected_image, {
          responseType: "arraybuffer"
        });
        download(response, "download.png");
      } catch (error) {
        // this.close();
      }
    },
    removeSelectedImage(index) {
      this.$refs["pictureInput"][index].removeImage();
      this.selected.attachments[index].img = null;
      this.selected.attachments[index].static = null;
    },
    toggleSelectAllOptions(flag) {
      if (flag === "currentPeriods") {
        if (this.likesAllPeriods) {
          this.selected.currentPeriods = [];
        } else {
          this.selected.currentPeriods = this.periodList;
        }
      } else if (flag === "currentWeeks") {
        if (this.likesAllSettedWeeks) {
          this.selected.currentWeeks = [];
        } else {
          this.selected.currentWeeks = this.weeks;
        }
      } else if (flag === "currentDays") {
        if (this.likesAllVisitdays) {
          this.selected.currentDays = [];
        } else {
          this.selected.currentDays = this.weekdays.map(el => el.value);
        }
      }
    },
    async onSaveClick(selected) {
      let item = { ...selected };
      for (let i = 0; i < this.attachmentLimitCount; i++) {
        if (item.attachments[i] && this.$refs["pictureInput"][i].file) {
          item.attachments[i].img = this.$refs["pictureInput"][i].file;
        }
      }
      if (this.$refs.form.validate()) {
        this.isSaving = true;
        let body = _.omit(item, ["attachments"]);
        let attachmentList = item["attachments"];
        let attachmentIdList = {};
        let attachmentIndex = 0;
        for (const attachmentItem of attachmentList) {
          console.log(attachmentItem);
          if (attachmentItem.img) {
            let { data } = await ApiService.uploadImage(attachmentItem.img);
            attachmentIdList[attachmentIndex] = data.id;
          }
          attachmentIndex++;
        }
        body["attachmentIdList"] = attachmentIdList;
        body["attachments"] = attachmentList;
        if (this.isResonsableRole) {
          body = _.omit(body, ["adminComments", "adminApprovalStatus"]);
        } else {
          body = _.omit(body, [
            "responsableComments",
            "responsableApprovalStatus"
          ]);
        }
        console.log(body);
        if (body.id) {
          try {
            await ApiService.put(`pos/newrequest/${body.id}`, body);
            logInfo("Éxito !");
            this.showEdit = false;
            this.$store.dispatch("auth/getGlobalState");
            this.getDataFromApi();
            this.isSaving = false;
          } catch (error) {
            this.isSaving = false;
          }
        }
      }
    },
    onChangeBrand(selectedBrandId, index) {
      console.log(selectedBrandId);
      console.log(this.surveyList);
      if (selectedBrandId) {
        this.selectedSurveyList[index] = this.surveyList.filter(
          el => el.brandId === selectedBrandId
        );
      } else {
        this.selectedSurveyList[index] = this.surveyList;
      }
      console.log(this.surveyList);
    },
    init() {
      this.getDataFromApi();
    }
  },
  async mounted() {
    this.init();
  }
};
</script>

<style>
span.required {
  color: #f00;
  font-weight: bold;
}
.theme--light.v-text-field--filled.v-input--is-readonly.readonly_component
  > .v-input__control
  > .v-input__slot {
  background: #c3c3c3;
}
.status_wrapper {
  margin-top: 0;
  color: #000;
}
.status_wrapper .bg-dark {
  background-color: #9f9f9f !important;
}
</style>
